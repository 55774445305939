import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, reset, change } from 'redux-form';
import moment from 'moment'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Editor } from '@tinymce/tinymce-react';
import { ProjectActions } from '../../actions/project.action';
import { templateActions } from '../../actions/template.action';
import RichTextEditor from 'react-rte';
import TextEditorRedux from '../common/TextEditorRedux'
import { getTemplateID } from '../../utils/common.helper'

const validate = values => {

    const errors = {}

    return errors
}

class ScopeOfWork extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tvalue:""
        }
    }

    componentDidMount = () =>{
        let { workType, templates, templateSet } = this.props,
        selected_template_id = this.props.match.params.template_id,
        carried_out_id = this.props.match.params.id,
        template_id = getTemplateID(selected_template_id, carried_out_id, workType, templates, templateSet, "MAIN")
        let workTypeObj = _.find(workType, ['id', this.props.match.params.id]);
        this.props.getWorkType()
        this.props.getAllScopeOfWork({
            project_id: this.props.project_details.id,
            workType: workTypeObj.shortcode  
        })       
    }

    onChangeT = (tvalue) => {
        if(tvalue == ""){
            this.setState({tvalue: " "});
        }else{
            this.setState({tvalue})
        }
        
    };
    onReset = () => {
        this.props.resetForm()
    }

    componentDidUpdate(prevProps, prevState) {
        let { project_scope } = this.props
        if(!_.isEqual(prevProps.project_scope, project_scope)){
            this.setState({
                tvalue: project_scope.scope
            })
        }
    }

    handleSubmit = (values) => {
        let { workType, templates, templateSet, project_details } = this.props
        let selected_template_id = this.props.match.params.template_id
        let carried_out_id = this.props.match.params.id
        let workTypeObj = _.find(workType, ['id', this.props.match.params.id]);
        let template_id = getTemplateID(selected_template_id, carried_out_id, workType, templates, templateSet, "MAIN")
        values.project_id = project_details.id
        values.template_id = template_id
        values.scope = this.state.tvalue
        values.workType = workTypeObj.shortcode  
        this.props.saveScopeOfWork(values)
    }
    copyContents = async () => {
        try {
          await navigator.clipboard.writeText("");
          console.log('Page URL copied to clipboard');
        } catch (err) {
          console.error('Failed to copy: ', err);
        }
      }
    getClipboardContents = async ()=> {
        return navigator.permissions.query({name: "clipboard-write"}).then(async result => {
            if (result.state == "granted" || result.state == "prompt") {
                try {
                    const text = await navigator.clipboard.readText();
                    return text;
                    console.log('Pasted content: ', text);
                  } catch (err) {
                    console.error('Failed to read clipboard contents: ', err);
                  }
            }
          });
        
    }
    getBrowser() {
        var ua=navigator.userAgent,tem,M=ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []; 
        if(/trident/i.test(M[1])){
            tem=/\brv[ :]+(\d+)/g.exec(ua) || []; 
            return {name:'IE',version:(tem[1]||'')};
            }   
        if(M[1]==='Chrome'){
            tem=ua.match(/\bOPR|Edge\/(\d+)/)
            if(tem!=null)   {return {name:'Opera', version:tem[1]};}
            }   
        M=M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
        if((tem=ua.match(/version\/(\d+)/i))!=null) {M.splice(1,1,tem[1]);}
        return M[0];
        // return {
        //   name: M[0],
        //   version: M[1]
        // };
     }

    render() {
        const { handleSubmit, submitting, project_scope, workType, user  } = this.props
        let tvalue = this.state.tvalue ||  project_scope.scope;
        var self = this;
        return (
            <Fragment>
                {_.isEmpty(project_scope) && <div className="spinner"></div>}
                <form onSubmit={handleSubmit(this.handleSubmit)}>
                    <p> The scope of work describes all of the work carried out on the project.
                        This can be a brief description or more detailed but should always be clear enough to allow for only one interpretation.
                    </p> 
                    <h5 className="mt-10 text-dark">Scope of work</h5>
                     <Editor
                            apiKey="vmdjvpiknwpg3i3z747i23y6yggjmitl8rdil1995zt3dek3"
                            // initialValue={tvalue}
                            value={tvalue}
                            disabled={user.type < 4 ?false:true}
                            id={tvalue}
                            init={{
                            height: 500,
                            menubar: false,
                            selector:"textarea",
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount',
                                'paste contextmenu'
                            ],
                            toolbar:
                            this.getBrowser() == "Chrome" ? 
                            'table | undo redo | cut copy myCustomToolbarButton| bold italic underline strikethrough | subscript superscript | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile pageembed template link ':
                            'table | undo redo | cut copy paste| bold italic underline strikethrough | subscript superscript | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile pageembed template link ', 
                            fullpage_default_font_family: "'Century Gothic';",
                            contextmenu:this.getBrowser() == "Chrome" ? 'link image | myCustomaddContextMenuButton':'link image paste',
                            content_style: 'table { max-width:100%}',
                            paste_data_images: true,
                                    setup: (editor) => {
                                        // console.log("editor.ui.registry",editor.ui.registry)
                                        function insertData() {
                                            self.getClipboardContents().then((text)=>{
                                                editor.insertContent(text);
                                            })
                                        }
                                        editor.ui.registry.addButton('myCustomToolbarButton', {
                                        icon: 'paste',
                                        onAction: () => insertData()
                                        });
                                        editor.ui.registry.addMenuItem('myCustomaddContextMenuButton', {
                                            icon: 'paste',
                                            text:'Paste',
                                            onAction: () => insertData()
                                        });
                                        
                                    },
                            plugin_preview_width: 200
                            }}
                            onEditorChange={this.onChangeT}
                        />

                    <div style={{marginTop: '20px', float: 'right'}}>
                        <button type="button" disabled={user.type < 4 ?false:true} onClick={this.onReset} className="btn ml-3 btn-default">Clear</button>
                        <button type="submit" disabled={submitting || user.type < 4 ?false:true} className="btn ml-3 btn-primary">{submitting ? 'Submitting': 'Save'}</button>
                    </div>
                </form>
            </Fragment>
        )
    }
}

ScopeOfWork = reduxForm({
    validate,
    form: 'ScopeOfWorkForm',
    destroyOnUnmount: true,
    enableReinitialize: true,
})(ScopeOfWork);

const mapStateToProps = (state, ownProps) => {
    let project_scope = state.project.project_scope
    return {
        project_scope,
        project_details : state.project.project_details,
        user            : state.auth.user,
        project_scope   : state.project.project_scope,
        workType        : state.templates.workType.data,
        templateSet     : state.templates.templateSet.data,
        templates       : state.templates.templates.data,
        initialValues: {
            scope: project_scope.scope
        }
    }
}

const mapDispatchToProps = (dispatch) => ({
    saveScopeOfWork: (data) => dispatch(ProjectActions.saveScopeOfWork(data)),
    getWorkType: () => dispatch(templateActions.getWorkType()),
    onChangeFieldValue: ({fieldName, fieldValue}) => dispatch(change('ScopeOfWorkForm', fieldName,  fieldValue)),
    resetForm:()=>{dispatch(reset('ScopeOfWorkForm'))},
    getAllScopeOfWork: (data) => dispatch(ProjectActions.getAllScopeOfWork(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ScopeOfWork)
