let initialValues = {
    company_details: {
        account_info:null
    },
    cancelSubscription:{
        requesting: false,
        isCanceled: false,
        user_id: null
    },
    trial:null,
    hidetrial:true,
    showSupportEmail: false,
    is_company_created:false,
}


export const companyReducer = (state = initialValues, action) => {
    switch (action.type) {

        case 'COMPANY/GET/DETAIL/SUCCESS':
            return {
                ...state,
                company_details: {
                    ...action.payload,
                    account_info: {
                        ...state.company_details.account_info,
                        // subscription_details: action.payload.subscription_details,
                        contact_email:action.payload.contact_email,
                        account_email: action.payload.stripe_email
                    },
                },
            }

        case 'COMPANY/GET/ACCOUNT/INFO/SUCCESS':
            return {
                ...state,
                company_details : {
                    ...state.company_details,
                    contact_email: action.payload.contact_email,
                    account_info: {
                        ...state.company_details.account_info,
                        subscription_details: action.payload.subscription_details,
                        contact_email:action.payload.contact_email,
                        account_email: action.payload.account_email
                    },
                }
            }

        case 'COMPANY/INSERT/DETAIL/FORM/SUCCESS':
        return {
            ...state,
            is_company_created:state.company_details._id?false:true
        }    
        case 'COMPANY/UPDATE/ACCOUNT/INFO/SUCCESS':
        return {
            ...state,
            company_details : {
                ...state.company_details,
                contact_email: action.payload.contact_email,
                account_info: {
                    ...state.company_details.account_info,
                    contact_email:action.payload.contact_email,
                    account_email: action.payload.account_email
                },
            }
        }
        case 'COMPANY/POST/CANCEL/SUBSCRIPTION/REQUEST':
            return  {
                ...state,
                cancelSubscription:{
                    requesting:true
                }
            }
        case 'COMPANY/POST/CANCEL/SUBSCRIPTION/SUCCESS':
            return  {
                ...state,
                cancelSubscription:{
                    requesting:false,
                    isCanceled: true,
                    user_id: action.payload.user_id
                }
            }
        case 'COMPANY/POST/CANCEL/SUBSCRIPTION/REASON/SUCCESS':
            return {
                ...state,
                cancelSubscription: {
                    requesting: false,
                    isCanceled: false,
                    user_id: null
                }
            }
        case 'COMPANY/GET/TRIAL_PERIOD/SUCCESS':
            return{
                ...state,
                trial:action.payload
            }
        case 'COMPANY/REMOVE/TRIAL':
            return {
                ...state,
                trial:{
                    ...state.trial,
                    hidetrial:true
                }
            }
        case 'COMPANY/EMAIL/OPTION/TOGGLE':
            return{
                ...state,
                showSupportEmail: !state.showSupportEmail                 
            }
      default:
          return {
              ...state
          }
    }
}