import React from 'react';
import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Paper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, } from '@material-ui/core';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, } from "../../_helpers";
import { connect } from 'react-redux';
import { StockActions } from "../../../app/actions/stock.action";
import { Link } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import startCase from 'lodash/startCase'

const rowsPerPageOptions = [10, 15, 25, 50];

const headRows = [
  { id: 'title', numeric: false, disablePadding: false, label: 'Group' },
  { id: 'stocks', numeric: false, disablePadding: false, label: 'Stocks' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Actions'  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, listing } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map(row => (
          <TableCell
            key={row.id}
            align={row.numeric ? 'right' : 'left'}
            sortDirection={orderBy === row.id ? order : false}
            className="row_alignment_css"
          >
            <TableSortLabel
              active={orderBy === row.id && listing.length > 1}
              direction={order}
              onClick={orderBy === row.id ? createSortHandler(row.id) : null}
              hideSortIcon={row.id !== 'title' || listing.length < 2}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

class Listing extends React.Component {

  state = {
    order: 'asc',
    orderBy: 'title',
    page: 0,
    dense: false,
    rowsPerPage: 10,
    isModalVisible: false,
    idToDelete: ""
  }

  componentDidMount() {
    this.props.stockListing();
  }

  handleRequestSort = (e, property) => {
    let { order, orderBy, page } = this.state;
    if(!order){
      order = 'asc';
    }
    const isDesc = orderBy === property && order === 'desc';
    this.setState({ order: isDesc ? 'asc' : 'desc', orderBy: property }, () => {
      this.props.stockListing({ sort: property, order: order === 'asc' ? -1 : 1 , page})
    })
  }

  handleChangePage = (e, newPage) => {
    const { rowsPerPage,  order, orderBy } = this.state;
    const new_page = newPage + 1;
    if (this.props.listing) {
      this.setState({ page: newPage })
      let extraFilters = {};
      if(order && orderBy){
        extraFilters = { sort: orderBy, order: order === 'asc' ? 1 : -1 }
      }
      this.props.stockListing({ current_page: new_page, per_page: rowsPerPage, ...extraFilters });
    }
  }

  handleChangeRowsPerPage = (e) => {
    this.setState({ 
      rowsPerPage: e.target.value,
      page: 0,
     })
    this.props.stockListing({ per_page: e.target.value });
  }

  handleDelete = (id) => this.props.deleteStock(id)

  openUpdateStockSidebar = (id) => {
    this.props.getStock(id);
    document.getElementById('update-stock-sidebar').style.display = 'block';
    document.body.classList.add("openRightBar");
    this.props.toggleSidebar();
  }

  openModal = (id) => {
    this.setState({ isModalVisible: true, idToDelete: id })
  }

  closeModal = () => {
    this.setState({ isModalVisible: false, idToDelete: "" })
  }

  handleDeleteStockModal = (delete_stock) => {
    const data = {
      id: this.state.idToDelete,
      delete_stock
    }
    const { rowsPerPage,  order, orderBy, page } = this.state;
    let extraFilters = {};
    if(order && orderBy){
      extraFilters = { sort: orderBy, order: order === 'asc' ? 1 : -1 }
    }
    this.props.deleteStock({data, refreshList: this.props.stockListing, listData: { current_page: page+1, per_page: rowsPerPage, ...extraFilters }});
    this.closeModal();
  }

  render() {
    const { dense, rowsPerPage, page, order, orderBy, } = this.state;
    const { listing, pagination, requesting, } = this.props;
    return (
      <>
        <div style={{ width: '100%', marginTop: '24px', }}>
          <Paper style={{ width: '100%', marginBottom: '16px', }}
            ref={element => {
              if (element) element.style.setProperty('box-shadow', 'none', 'important');
            }}>
            <div style={{ overflowX: 'auto' }}>
              <Table
                style={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={this.handleRequestSort}
                  listing={listing}
                />
                <TableBody>
                  {!requesting && listing && listing.length === 0 && (
                    <TableRow tabIndex={-1}>
                      <TableCell colSpan={3} align={"center"}>No Records Found!</TableCell>
                    </TableRow>
                  )}
                  {listing && listing.length > 0 && listing.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell className="row_alignment_css">
                          <Link
                            style={{ cursor: 'pointer' }}
                            to={{
                              pathname: "/stock_detail",
                              data: { id: row.id, title: row.lable }
                            }}
                            className="no_style_anchor"
                            onClick={() => this.props.getStock(row.id)}>
                            {startCase(row.label)}
                          </Link>
                        </TableCell>
                        {/* <TableCell className="row_alignment_css" style={{ cursor: 'pointer' }} onClick={() => this.openUpdateStockSidebar(row.id)}>{row.count}</TableCell> */}
                        <TableCell className="row_alignment_css" >
                        <Link
                            style={{ cursor: 'pointer' }}
                            to={{
                              pathname: "/stock_detail",
                              data: { id: row.id, title: row.title }
                            }}
                            className="no_style_anchor"
                            onClick={() => this.props.getStock(row.id)}>
                            {row.count}
                          </Link></TableCell>

                        <TableCell className="row_alignment_css">{row.title != "default" &&
                          <button className="trash_button" onClick={() => this.openModal(row.id)}><SVG src={toAbsoluteUrl("/media/svg/icons/custom/delete-instance.svg")}
                          /></button>}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {requesting && <div className="spinner"></div>}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={pagination ? pagination.total : -1}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{ 'aria-label': 'Previous Page', }}
              nextIconButtonProps={{ 'aria-label': 'Next Page', }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              labelDisplayedRows={({ from, to, count }) => {
                if (pagination === null) {
                  return `0-0 of 0`
                }
                return `${from}-${to} of ${count}`
              }}
            />
          </Paper>
        </div>
        <Dialog
          className="model-support"
          //modal={false}
          contentClassName="model-top-overlay no-overlay"
          open={this.state.isModalVisible}
        >
          <DialogTitle id="alert-dialog-title">
            Delete Stock Group?
          <IconButton aria-label="close" onClick={this.closeModal} className="float-right p-1">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want to delete the STOCK ITEMS too related to this Stock Group?
          </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button className="btn btn-primary font-weight-bold px-9 py-4 my-3" onClick={() => this.handleDeleteStockModal(false)}>No</button>
            <button className="btn btn-primary font-weight-bold px-9 py-4 my-3" onClick={() => this.handleDeleteStockModal(true)}>Yes</button>
          </DialogActions>
        </Dialog>
      </>
    );
  }


}

const mapStateToProps = (state, ownProps) => {
  const { stocklisting: { data, pagination, requesting, } } = state.stock;
  return {
    listing: data,
    pagination: pagination,
    requesting,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  stockListing: (data) => dispatch(StockActions.getAll(data)),
  deleteStock: (data) => dispatch(StockActions.deleteOne(data)),
  getStock: (data) => dispatch(StockActions.getOne(data)),
  toggleSidebar: () => dispatch(StockActions.toggleSidebar()),
})


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Listing);